import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Links from '../../../marketplace/common/Typography/Links';
import useScreenType from '../../../../hooks/checkScreenType';
import PrimaryButton from '../../../../common/button/Button';
import CustomAlert from '../marketplace-link/CustomAlert';
import notAvailable from '../../../../../assets/Marketplace/Image not available 1.svg';

import UIPC_logo from '../../../../../assets/Marketplace/EcoLabels/UIPC-I.svg';
import IGBC_logo from '../../../../../assets/Marketplace/EcoLabels/IGBC.svg';
import Griha_logo from '../../../../../assets/Marketplace/EcoLabels/Griha.svg';
import GreenPro_logo from '../../../../../assets/Marketplace/EcoLabels/GreenPro.svg'; // exceeds 500
import Green_Label_logo from '../../../../../assets/Marketplace/EcoLabels/Green Label.svg';
import GreenCo_logo from '../../../../../assets/Marketplace/EcoLabels/GreenCo.svg';
import Green_Assure_logo from '../../../../../assets/Marketplace/EcoLabels/GreenAssure.svg';
import ECBC_logo from '../../../../../assets/Marketplace/certificationfallback.svg';
import LEED_logo from '../../../../../assets/Marketplace/EcoLabels/Leed.svg';

const useStyles = makeStyles((theme) => ({
  cardDesktop: {
    width: '220px',
    height: '340px',
    // margin: '10px',
    marginRight: '2%',
    marginBottom: '2%',
    boxShadow: 'none',
    border: '1px solid #00000033',
    borderRadius: '10px',
  },
  cardDesktopSelected: {
    width: '220px',
    height: '340px',
    // margin: '10px',
    marginRight: '2%',
    marginBottom: '2%',
    boxShadow: 'none',
    border: '2px solid rgba(38, 224, 163, 1)',
    borderRadius: '10px',
  },
  cardMobile: {
    width: '183px',
    margin: '10px',
    boxShadow: 'none',
    border: '1px solid #00000033',
    borderRadius: '10px',
  },
  cardMobileSelected: {
    width: '183px',
    margin: '10px',
    boxShadow: 'none',
    border: '2px solid rgba(38, 224, 163, 1)',
    borderRadius: '10px',
  },
  media: {
    margin: 10,
    height: 150,
    backgroundSize: 'contain',
  },
  mediamobile: {
    height: 100,
    margin: 10,
    backgroundSize: 'contain',
  },
  boxStyle: {
    width: '40px',
    height: '20px',
    borderRadius: '4px',
    border: '1px solid rgba(38, 224, 163, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 150,
    right: 10,
  },
  name: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  subname: {
    fontFamily: 'Lato',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '12px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  description: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textAlign: 'left',
  },
  icon: {
    textAlign: 'left',
    paddingTop: '8%',
  },
  bttongridstyle: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  addIcon: {
    fontSize: '25px',
    backgroundColor: 'rgba(26, 184, 232, 1)',
    color: 'rgba(255, 255, 255, 1)',
    margin: 10,
    borderRadius: '5px',
  },
  buttondivstyle: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    borderRadius: '7px',
    cursor: 'pointer',
  },
  typostyle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: 'rgba(26, 184, 232, 1)',
    fontWeight: 700,
    marginRight: '5px',
  },
  defaulttypostyle: {
    display: 'flex',
    alignItems: 'center',
    // width: "100%",
    fontSize: '8px',
    lineHeight: '20px',
    color: 'rgba(38, 224, 163, 1)',
    fontWeight: 700,
    // marginRight: "5px"
  },
  defaultText: {
    fontFamily: 'Lato',
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.7142857313156128px',
    textAlign: 'center',
  },
}));

export function getIcon(name) {
  let icons = [
    { name: 'ECBC', icon: ECBC_logo },
    { name: 'Green Assure', icon: Green_Assure_logo },
    { name: 'GreenCo', icon: GreenCo_logo },
    { name: 'Green Label', icon: Green_Label_logo },
    { name: 'GreenPro', icon: GreenPro_logo },
    { name: 'Griha', icon: Griha_logo },
    { name: 'IGBC', icon: IGBC_logo },
    { name: 'LEED', icon: LEED_logo },
    { name: 'UIPC-I', icon: UIPC_logo },
  ];
  for (let i = 0; i < icons.length; i++) {
    if (icons[i].name === name) {
      return icons[i].icon;
    }
  }
}

const InterventionProductCard = ({ product, defaultProduct, selectedProduct, section, onRemoveProduct, onSelectProduct }) => {
  const classes = useStyles();
  const { isMobile } = useScreenType();
  const [isHover, setIsHover] = useState(false);
  const [isImageAccessible, setIsImageAccessible] = useState(false);
  const [open, setOpen] = useState(false);
  const { name = '', shortDescription = '', ecolabels = [], id, category, image, brand, identifier } = product;

  useEffect(() => {
    if (image && image.trim() !== '') {
      const img = new Image();
      img.src = image;
      img.onload = () => setIsImageAccessible(true);
      img.onerror = () => setIsImageAccessible(false);
    }
  }, [product]);

  const handleSelectVendor = async () => {
    await onSelectProduct();
  };
  const handleRemove = async () => {
    await onRemoveProduct();
    setOpen(false);
  };

  const Hover = () => {
    const isSelectedProduct = selectedProduct === identifier;
    const isDefaultProduct = defaultProduct === identifier;
    return (
      <>
        <CardContent style={{ paddingLeft: '15px' }}>
          <Typography gutterBottom className={classes.name} style={{ paddingTop: '5%', textAlign: 'left' }}>
            {`${name.length > 21 ? `${name.slice(0, 20)}...` : name}`}
          </Typography>
          <Typography gutterBottom className={classes.subname} style={{ paddingTop: '5%', textAlign: 'left' }}>
            {brand?.name}
          </Typography>
          {!isSelectedProduct && !isDefaultProduct && (
            <>
              <Grid container item xs={12} className={classes.bttongridstyle} style={{ paddingTop: '80%' }}>
                <PrimaryButton
                  onClick={handleSelectVendor}
                  children={section === 'shortlisted' ? 'select product' : 'deselect product'}
                  style={{
                    width: '90%',
                    boxShadow: 'none',
                  }}
                />
              </Grid>
              <Grid container item xs={12} className={classes.bttongridstyle}>
                {<CustomAlert handleRemove={handleRemove} open={open} setOpen={setOpen} />}
              </Grid>
            </>
          )}
          {!isSelectedProduct && isDefaultProduct && (
            <>
              <Typography
                className={classes.defaultText}
                style={{
                  paddingTop: '80%',
                  width: '90%',
                  textAlign: 'center',
                  paddingLeft: '10%',
                }}
              >
                DEFAULT PRODUCT
              </Typography>
              <Grid container item xs={12} className={classes.bttongridstyle}>
                <PrimaryButton
                  onClick={handleSelectVendor}
                  children={section === 'shortlisted' ? 'select product' : 'deselect product'}
                  style={{
                    width: '90%',
                    boxShadow: 'none',
                  }}
                />
              </Grid>
            </>
          )}
          {isSelectedProduct && isDefaultProduct && (
            <>
              <Typography
                className={classes.defaultText}
                style={{
                  paddingTop: '80%',
                  width: '90%',
                  textAlign: 'center',
                  paddingLeft: '10%',
                }}
              >
                DEFAULT PRODUCT CANNOT BE REMOVED
              </Typography>
              {/* <Grid container item xs={12} className={classes.bttongridstyle}>
                <PrimaryButton
                  onClick={handleSelectVendor}
                  children={section === 'shortlisted' ? 'select product' : 'deselect product'}
                  style={{
                    width: '90%',
                    boxShadow: 'none',
                  }}
                />
              </Grid> */}
            </>
          )}
          {isSelectedProduct && !isDefaultProduct && (
            <>
              <Grid container item xs={12} className={classes.bttongridstyle} style={{ paddingTop: '80%' }}>
                <PrimaryButton
                  onClick={handleSelectVendor}
                  children={section === 'shortlisted' ? 'select product' : 'deselect product'}
                  style={{
                    width: '90%',
                    boxShadow: 'none',
                  }}
                  disabled={true}
                  backgroundColor="linear-gradient(90deg, #1FDEDB80 0%, #42E19880 50%, #42E19880 50%)"
                  textcolor="rgba(255, 255, 255, 1)"
                />
              </Grid>
              <Grid container item xs={12} className={classes.bttongridstyle}>
                <CustomAlert handleRemove={handleRemove} open={open} setOpen={setOpen} disabled={true} />
              </Grid>
            </>
          )}
        </CardContent>
      </>
    );
  };

  const Display = () => {
    return (
      <a
        target="_blank"
        onclick="return false;"
        href={`/marketplace/categories/${category?.id}/products/${id}`}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <CardActionArea>
          <CardMedia
            // style={{ paddingTop: '10%' }}
            className={isMobile ? classes.mediamobile : classes.media}
            image={isImageAccessible ? image : notAvailable}
            title={name}
          />
          {defaultProduct === identifier && (
            <Box className={classes.boxStyle}>
              <Typography className={classes.defaulttypostyle} style={{ alignItems: 'center', justifyContent: 'center' }}>
                Default
              </Typography>
            </Box>
          )}
          <CardContent style={{ paddingLeft: '15px' }}>
            <Links
              href={`/marketplace/categories/${category?.id}/products/${id}`}
              variant="string"
              tag={`${name.length > 21 ? `${name.slice(0, 20)}...` : name}`}
              isBold={false}
              className={classes.name}
              color="inherit"
            />
            {/* <Typography gutterBottom className={classes.subname} style={{ paddingTop: '5%', textAlign: 'left' }}>
              {identifier}
            </Typography> */}
            <Typography gutterBottom className={classes.subname} style={{ paddingTop: '5%', textAlign: 'left' }}>
              {brand?.name}
            </Typography>
            <Typography
              className={classes.description}
              style={{
                color: '#9E9E9E',
                paddingTop: '5%',
                height: '40px',
              }}
            >
              {shortDescription > 50 ? `${shortDescription.slice(0, 50)}...` : shortDescription}
            </Typography>
            <Typography className={classes.icon}>
              {ecolabels?.map((name) => (getIcon(name) ? <img key={name} src={getIcon(name)} alt="..." height={23} width={23} /> : null))}
            </Typography>
          </CardContent>
        </CardActionArea>
      </a>
    );
  };

  const getCardStyle = () => {
    // console.log(selectedProduct, identifier, 'selectedProduct')
    if (isMobile) {
      return selectedProduct !== undefined ? (selectedProduct === identifier ? classes.cardMobileSelected : classes.cardMobile) : classes.cardMobile;
    } else {
      return selectedProduct !== undefined ? (selectedProduct === identifier ? classes.cardDesktopSelected : classes.cardDesktop) : classes.cardDesktop;
    }
  };

  return (
    <Card
      fullwidth
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={getCardStyle()}
      style={{ position: 'relative', overflow: 'visible' }}
    >
      {isHover ? <Hover /> : <Display />}
    </Card>
  );
};
export default InterventionProductCard;
