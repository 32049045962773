import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, FormControl, Select, MenuItem } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import PrimaryButton from '../../../../common/button/Button';
import CloseIcon from '@material-ui/icons/Close';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import Fields from '../../../../common/textfield/Fields';
import useApiRequest from '../../../../hooks/useApiRequest';
import { useParams } from 'react-router';
import axios from '../../../../../config/Axios';
import swal from 'sweetalert';
import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';

export const AllProjectContext = React.createContext();

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '650px',
    outline: 'none',
    minHeight: 500,
    maxHeight: 700,
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 3),
  },
  modalhead: {
    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '20px',
    lineHeight: '25px',
    textAlign: 'left',
    letterSpacing: '0.714286px',
    color: '#000000',
  },
  modalsubhead: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '10px',
    textAlign: 'left',
    letterSpacing: '0.714286px',
    color: 'rgba(0, 0, 0, 0.38)',
    marginTop: '2%',
  },
  close: {
    marginLeft: '95%',
    cursor: 'pointer',
  },
  custom: {
    marginTop: '35%',
    fontSize: '14px',
    fontWeight: 850,
    lineHeight: '24px',
    letterSpacing: '0.71px',
    color: '#00C58A',
    cursor: 'pointer',
  },
  fieldname: {
    fontStyle: 'medium',
    fontWeight: 500,
    lineHeight: '22px',
    fontSize: '16px',
    textAlign: 'left',
    letterSpacing: '0.5px',
    color: '#666666',
    // marginBottom: '4%',
  },
  spacing: {
    marginTop: '4%',
  },
  spacing2: {
    marginTop: '2%',
  },
  formControl: {
    minWidth: 155,
    '& > *': {
      borderRadius: '50px',
      height: '7vh',
    },
  },
  // select: {
  //   overflow: 'hidden',
  // },
}));

const AddCustomIntervention = (props) => {
  const classes = useStyles();
  const [updateState, setUpdateState] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState('');
  const [name, setName] = React.useState(''); //
  const [capex, setCapex] = React.useState(''); //
  const [opex, setOpex] = React.useState(''); //
  const [resoucesaving, setResourcesaving] = React.useState(''); //
  const [carbonsaving, setCarbonSaving] = React.useState(''); //
  const [user, setUser] = React.useState('');
  const [description, setDescription] = useState('');
  const [totalCapex, setTotalCapex] = useState(0);
  const [nameError, setNameError] = useState(false);
  const [count, setCount] = useState(0);
  let { id } = useParams();

  const { data: response, isFetching, isError } = useAppApiRequest(`/authv2/whoami`, { method: 'GET' }, [], true);
  const { data } = useAppApiRequest(
    `/custom-intervention/${id}`,
    { method: 'GET' },
    [id],
    id !== undefined && props.editCustomintervention === true && props.editCustominterventionID
  );

  // const [{ status, response }, makeRequest] = useApiRequest(`/authv2/whoami`, {
  //   verb: 'get',
  // });
  // if (status === null) makeRequest();

  useEffect(() => {
    if (response) {
      setUser(response?.data?.data?.data);
    }
  }, [response]);

  useEffect(() => {
    if (props.editCustomintervention === true && props.editCustominterventionID) {
      let id = props.editCustominterventionID.split('.')[1];
      axios.get(`/custom-intervention/${id}`).then((response) => {
        if (response && response.data.data && response.data.data.data) {
          axios.get(`/categorySchemeV2/${response.data.data.data.category}`).then((res) => {
            if (res && res.data.data && res.data.data.data) {
              setUpdateState(true);
              setCategory(res.data.data.data.name);
              setName(response.data.data.data.name);
              setCapex(response.data.data.data.capex);
              setOpex(response.data.data.data.opex);
              setCarbonSaving(response.data.data.data.carbonSavings);
              setResourcesaving(response.data.data.data.resourceSavings);
              props.handleOpenBackdrop(false);
              setTotalCapex(response.data.data.data.totalCapex);
              setDescription(response.data.data.data.description);
              setOpen(true);
            }
          });
        }
      });
    } else {
      setOpen(false);
      setUpdateState(false);
    }
  }, [props]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (props.editCustomintervention === true && props.editCustominterventionID) {
      props.editModalClose(false);
      setUpdateState(false);
      props.deselectID('');
      setCategory('');
      setName('');
      setCapex('');
      setOpen(false);
      setOpex('');
      setResourcesaving('');
      setCarbonSaving('');
      // setOpen(false);
      setTotalCapex(0);
      setNameError(false);
      setCount(0);
      setObj({});
    } else {
      setCategory('');
      setName('');
      setCapex('');
      setOpen(false);
      setOpex('');
      setResourcesaving('');
      setCarbonSaving('');
      setOpen(false);
      setTotalCapex(0);
      setNameError(false);
      setCount(0);
      setObj({});
    }
  };

  const handledeleteIntervention = (customInterventionID) => {
    let c_id = customInterventionID.split('.')[1];
    axios
      .delete(`/custom-intervention/${c_id}`)
      .then((res) => {
        if (res && res.data.data) {
          swal({ icon: 'success', title: 'Custom intervention Deleted' });
          props.handleOpenBackdrop(true);

          setCategory('');
          setName('');
          setCapex('');
          setOpex('');
          setResourcesaving('');
          setCarbonSaving('');
          // setOpen(false);
          props.editModalClose(false);
          setUpdateState(false);
          props.deselectID('');
          props.handleRefresh();
        }
      })
      .catch((err) => {
        console.log(err, 'err');
      });
  };

  const handleUpdateCustomintervention = (customInterventionID) => {
    let c_id = customInterventionID.split('.')[1];
    const send = {
      projectID: id,
      name: name,
      category: category,
      capex: Number(capex),
      opex: Number(opex),
      resourceSavings: Number(resoucesaving),
      carbonSavings: Number(carbonsaving),
      createdBy: user._id,
      totalCapex: Number(totalCapex),
      description: description,
    };
    axios
      .put(`/custom-intervention/${c_id}`, send)
      .then((res) => {
        if (res && res.data.data) {
          swal({ icon: 'success', title: 'Custom intervention Updated' });
          setCategory('');
          setName('');
          setCapex('');
          setOpex('');
          setResourcesaving('');
          setCarbonSaving('');
          // setOpen(false);
          props.editModalClose(false);
          setUpdateState(false);
          props.deselectID('');
          props.handleOpenBackdrop(true);
          props.handleRefresh();
        }
      })
      .catch((err) => {
        console.log(err, 'err');
      });
  };

  const handleClick = () => {
    let val = name && name;
    var val2 = val.replace('\\s+', '').trim();
    const send = {
      projectID: id,
      name: val2 && val2.toLowerCase(),
      category: category,
      capex: Number(capex),
      opex: Number(opex),
      resourceSavings: Number(resoucesaving),
      carbonSavings: Number(carbonsaving),
      createdBy: user._id,
      totalCapex: Number(totalCapex),
      description: description,
    };

    axios.post('/custom-intervention/validate', send).then((response) => {
      if (response.data && response.data.data && response.data.data.data === false) {
        axios.post('/custom-intervention', send).then((res) => {
          if (res && res.data.data) {
            props.handleRefresh();
            swal({ icon: 'success', title: 'Data Saved' });
            setCategory('');
            setName('');
            setCapex('');
            setOpen(false);
            setOpex('');
            setResourcesaving('');
            props.handleOpenBackdrop(true);
            setCarbonSaving('');
            setTotalCapex(0);
            setNameError(false);
            setCount(0);
            setObj({});
          }
        });
      } else {
        setNameError(true);
        // swal({ icon: 'error', title: 'Intervention already exists' });
      }
    });
  };

  // Assuming obj is declared outside of the component or in the component's state
  // Assuming obj and count are declared outside of the component or in the component's state
  const [obj, setObj] = useState({});
  // const [count, setCount] = useState(0);

  // const handleActivatestatus = (name, value) => {
  //   if (!obj[name]) {
  //     if (value !== '') {
  //       setCount(count + 1);
  //       const updatedObj = { ...obj };
  //       setObj(updatedObj);
  //     }
  //   } else if (obj[name]) {
  //     if (value === '') {
  //       setCount((prevCount) => Math.max(0, prevCount - 1));
  //     }
  //   }
  // };
  const handleActivatestatus = (name, value) => {
    const updatedObj = { ...obj };

    if (!updatedObj[name] && value !== '') {
      setCount((prevCount) => prevCount + 1);
      updatedObj[name] = true; // Mark the name as activated
    } else if (updatedObj[name] && value === '') {
      setCount((prevCount) => Math.max(0, prevCount - 1)); // Ensure count never goes below 0
    }

    setObj(updatedObj);
  };

  const [form, setForm] = useState({
    name: '',
    category: '',
    capex: '',
    totalCapex: '',
    opex: '',
    resourceSavings: '',
    carbonSavings: '',
    description: '',
  });

  const handleUpdateForm = (key, value) => {
    setForm((oldFormValues) => {
      return {
        ...oldFormValues,
        [key]: value,
      };
    });
  };

  const handleCreateIntervention = async () => {
    const body = {
      projectID: id,
      name: form?.name,
      category: form?.category,
      capex: Number(form?.capex),
      opex: Number(form?.opex),
      resourceSavings: Number(form?.resourceSavings),
      carbonSavings: Number(form?.carbonSavings),
      createdBy: user._id,
      totalCapex: Number(form?.totalCapex),
      description: form?.description,
    };

    axios.post('/custom-intervention/validate', body).then((response) => {
      console.log(response, 'validate');
      if (response.data && response.data.data && response.data.data.data === false) {
        axios.post('/custom-intervention', body).then((res) => {
          console.log(res, 'custom-intervention');
          // if (res && res.data.data) {
          //   props.handleRefresh();
          //   swal({ icon: 'success', title: 'Data Saved' });
          //   setCategory('');
          //   setName('');
          //   setCapex('');
          //   setOpen(false);
          //   setOpex('');
          //   setResourcesaving('');
          //   props.handleOpenBackdrop(true);
          //   setCarbonSaving('');
          //   setTotalCapex(0);
          //   setNameError(false);
          //   setCount(0);
          //   setObj({});
          // }
        });
      } else {
        // setNameError(true);
        // swal({ icon: 'error', title: 'Intervention already exists' });
      }
    });
  };

  console.log({ form }, 'FORM');

  return (
    <div>
      <Typography variant="h6" className={classes.custom} onClick={handleOpen} data-tour="custom">
        <AddBoxRoundedIcon style={{ paddingRight: '3px', marginBottom: '-5px', fontSize: '19px' }} />
        ADD INTERVENTION
      </Typography>
      <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon className={classes.close} onClick={handleClose} />
          {updateState && updateState === true ? (
            <Typography className={classes.modalhead}>Update Custom Intervention</Typography>
          ) : (
            <Typography className={classes.modalhead}>Add A New Custom Intervention</Typography>
          )}
          <Typography className={classes.modalsubhead}>All fields are mandatory</Typography>
          <Grid container item xs={12} className={classes.spacing}>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>Name</Typography>
                </Grid>
                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <Fields
                      disabled={updateState === true ? true : false}
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => {
                        setNameError(false);
                        setName(e.target.value);
                        handleActivatestatus('name', e.target.value);
                        handleUpdateForm('name', e.target.value);
                      }}
                      fullWidth
                      required
                      error={nameError ? true : false}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right"></Grid>
                </Grid>
                {nameError && (
                  <Typography
                    style={{
                      color: 'rgba(237, 94, 104, 1)',
                      fontSize: '12px',
                      paddingLeft: '10px',
                    }}
                  >
                    Name not unique
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>Category</Typography>
                </Grid>
                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Select
                        className={classes.select}
                        value={category}
                        onChange={(e) => {
                          e.preventDefault();
                          setCategory(e.target.value);
                          handleActivatestatus('category', e.target.value);
                          handleUpdateForm('category', e.target.value);
                        }}
                      >
                        <MenuItem value="Energy" name="Energy">
                          Energy
                        </MenuItem>
                        <MenuItem value="Water" name="Water">
                          Water
                        </MenuItem>
                        <MenuItem value="Waste" name="Waste">
                          Waste
                        </MenuItem>
                        <MenuItem value="Materials" name="Materials">
                          Materials
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right"></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.spacing}>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>CAPEX Increase</Typography>
                </Grid>
                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <Fields
                      placeholder="Enter Amount"
                      value={capex}
                      onChange={(e) => {
                        e.preventDefault();
                        setCapex(e.target.value);
                        handleActivatestatus('capex', e.target.value);
                        handleUpdateForm('capex', e.target.value);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right">
                    <Typography variant="h6" style={{ marginTop: '27%' }}>
                      &nbsp; Rs
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>Total CAPEX</Typography>
                </Grid>
                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <Fields
                      placeholder="Enter Amount"
                      value={totalCapex}
                      onChange={(e) => {
                        e.preventDefault();
                        setTotalCapex(e.target.value);
                        handleActivatestatus('totalCapex', e.target.value);
                        handleUpdateForm('totalCapex', e.target.value);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right">
                    <Typography variant="h6" style={{ marginTop: '27%' }}>
                      &nbsp; Rs
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.spacing}>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>OPEX Savings</Typography>
                </Grid>

                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <Fields
                      placeholder="Enter Amount"
                      value={opex}
                      onChange={(e) => {
                        e.preventDefault();
                        setOpex(e.target.value);
                        handleActivatestatus('opex', e.target.value);
                        handleUpdateForm('opex', e.target.value);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right">
                    <Typography variant="h6" style={{ marginTop: '27%' }}>
                      &nbsp; Rs
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>Resource Savings</Typography>
                </Grid>

                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <Fields
                      placeholder="Enter Value"
                      value={resoucesaving}
                      onChange={(e) => {
                        e.preventDefault();
                        setResourcesaving(e.target.value);
                        handleActivatestatus('resoucesaving', e.target.value);
                        handleUpdateForm('resourceSavings', e.target.value);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right"></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.spacing}>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>Carbon Savings</Typography>
                </Grid>

                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <Fields
                      placeholder="Enter Value"
                      value={carbonsaving}
                      onChange={(e) => {
                        e.preventDefault();
                        setCarbonSaving(e.target.value);
                        handleActivatestatus('carbonsaving', e.target.value);
                        handleUpdateForm('carbonSavings', e.target.value);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right">
                    <Typography variant="h6" style={{ marginTop: '27%' }}>
                      &nbsp; Tons
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.spacing}>
            <Grid item xs={12}>
              <Grid container direction="column" item xs={12}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>Intervention Description (optional)</Typography>
                </Grid>
                {/* <Grid container item xs={12} className={classes.spacing2}> */}
                <Grid item xs={12} alignItems="left">
                  <Fields
                    placeholder="Enter Value"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      handleUpdateForm('description', e.target.value);
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                {/* </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <>
            <Grid container alignItems="center" item xs={12} style={{ marginTop: '8%' }}>
              {updateState && updateState === true ? (
                <>
                  <Grid item xs={4} align="left">
                    <Typography
                      variant="h6"
                      style={{ color: '#ED5E68', fontSize: '12px', fontWeight: 900, cursor: 'pointer' }}
                      onClick={() =>
                        swal({
                          title: `Are you sure you want to permanently delete ${name} ?`,
                          icon: 'warning',
                          buttons: true,
                          dangerMode: true,
                        }).then((willDelete) => {
                          if (willDelete) {
                            handledeleteIntervention(props.editCustominterventionID && props.editCustominterventionID);
                          }
                        })
                      }
                    >
                      DELETE INTERVENTION
                    </Typography>
                  </Grid>
                  <Grid item xs={2} />{' '}
                </>
              ) : (
                <Grid item xs={6} align="left" />
              )}
              <Grid item xs={2} align="left">
                <Typography
                  variant="h6"
                  style={{ color: '#1FDEDB', fontSize: '12px', fontWeight: 900, cursor: 'pointer' }}
                  onClick={() => {
                    handleClose();
                    setCategory('');
                    setName('');
                    setCapex('');
                    setOpex('');
                    setResourcesaving('');
                    setCarbonSaving('');
                    setUser('');
                    setDescription('');
                    setTotalCapex(0);
                    setNameError(false);
                    setCount(0);
                    setObj({});
                  }}
                >
                  CANCEL
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {updateState && updateState === true ? (
                  <PrimaryButton
                    children="update intervention"
                    onClick={() => handleUpdateCustomintervention(props.editCustominterventionID && props.editCustominterventionID)}
                  />
                ) : (
                  <PrimaryButton
                    children="create intervention"
                    onClick={() => handleCreateIntervention()}
                    // disabled={count === 7 ? false : true}
                    disabled={true}
                  />
                )}
              </Grid>
            </Grid>
          </>
        </div>
      </Modal>
    </div>
  );
};
export default AddCustomIntervention;
