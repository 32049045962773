import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import InterventionDetailsHeader from './main/InterventionDetailsHeader';

import Backdrop from '@material-ui/core/Backdrop';

import { useParams } from 'react-router';

import CircularProgress from '@material-ui/core/CircularProgress';
// import WasteMandateImage from '../../../../assets/interventions/WasteMandateImage.svg';
// import mandateGeneralsIcon from '../../../../assets/mandateGeneralsIcon.svg';
import { PreviousPageContext } from '../../../../routing/IndexRouting';
import { MarketPlaceLink } from './marketplace-link/MarketplaceLink';

import InterventionDetailsContext, { useInterventionDetailsContext } from './context/InterventionDetails';
import InterventionDetailsSummary from './main/InterventionDetailsSummary';
import InterventionDetailsTour from './main/InterventionDetailsTour';
import ApiError from '../../../common/Error/ApiError';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: '56px',
    padding: theme.spacing(3),
  },
  root: {
    paddingTop: '3%',
  },
  overview: {
    flexGrow: '1',
    maxWidth: '100%',
    flexBasis: '100%',
  },
  rightOverview: {
    marginTop: '1%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: 900,
    minWidth: 700,
    minHeight: 400,
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    borderRadius: '10px',
  },
  paperModal: {
    position: 'absolute',
    outline: 'none',
    height: 'auto',
    width: 'auto',
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  vendorGrid: {
    marginTop: '3%',
  },
  close: {
    marginLeft: '95%',
  },
  backdrop: {
    zIndex: 1,
    color: '#fff',
  },
}));

function InterventionDetails() {
  const { intervention, meta, request } = useInterventionDetailsContext();
  const params = useParams();
  const classes = useStyles();
  // const [modalStyle] = React.useState(getModalStyle);

  const { setPreviousPage, setProjectID, setIntervnetionMappingID } = useContext(PreviousPageContext);

  // TODO: Why are we using a PreviousPageContext. Can it be managed easily using react-router-dom?
  useEffect(() => {
    if (params?.id && params?.iid) {
      setPreviousPage('interventionDetails');
      setProjectID(params?.id);
      setIntervnetionMappingID(params?.iid);
    }
  }, [params]);

  /* Update current sandbox: Currently not in use */
  // refresh after update sandbox dirty value
  // const handleRefresh2 = () => {};

  // const handleUpdateCurrentSandbox = () => {
  //   if (activeSandboxId && activeSandbox) {
  //     const body = {
  //       type: 'persistent',
  //       values: activeSandbox && activeSandbox[0].dirty,
  //     };
  //     axios
  //       .put(`/projectv2/${id}/interventions/${iidd}/sandboxes/${activeSandboxId}`, body)
  //       .then((res) => {
  //         if (res) {
  //           setOpenSaveChanges(false);
  //           handleRefresh2();
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  /* Create new sandbox: Currently not in use */
  // const createnewSandbox = () => {
  //   if (activeSandboxId && activeSandbox && userDetails) {
  //     const body = {
  //       dirty: [],
  //       message: 'message',
  //       persistent: activeSandbox && activeSandbox[0].dirty,
  //       createdBy: userDetails && userDetails._id,
  //     };
  //     axios
  //       .post(`/projectv2/${id}/interventions/${iidd}/sandboxes`, body)
  //       .then((res) => {
  //         if (res) {
  //           handleRefresh2();
  //           setOpenSaveChanges(false);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  // revert changes - used in intervention parameters
  // const revertChanges = (value) => {
  //   if (value) {
  //     const body = {
  //       sandboxId: value && value,
  //     };
  //     axios
  //       .put(`/projectv2/${id}/interventions/${iidd}/sandboxes`, body)
  //       .then((res) => {
  //         if (res) {
  //           handleRefresh2();
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  if (request?.isError) {
    return (
      <Grid className={classes.main}>
        <ApiError />
      </Grid>
    );
  }

  return (
    <Grid className={classes.main}>
      <Backdrop open={request?.isFetching} className={classes.backdrop} style={{ zIndex: 3, color: '#66E3BE' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <>
        <InterventionDetailsTour />
        <InterventionDetailsHeader />
        <InterventionDetailsSummary />
        <MarketPlaceLink interventionname={intervention?.name} />

        {/* <Modal
          // open={open}
          //  onClose={handleModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paperModal}>
            <CloseIcon
              className={classes.close}
              // onClick={handleModalClose}
            />
            <Typography variant="h5">Our Team is Working with our Vendor partners to bring this feature to you at the earliest.</Typography>
          </div>
        </Modal> */}

        {/* Sanbox creation and updating: Currently not in use */}
        {/* <Dialog open={openSaveChanges} onClose={handleCloseSaveChanges} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <b>Do you want to update this sandbox or create a new one?</b>
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleUpdateCurrentSandbox} color="primary">
              Update
            </Button>
            <Button onClick={createnewSandbox} color="primary" autoFocus>
              Create
            </Button>
          </DialogActions>
        </Dialog> */}
      </>
    </Grid>
  );
}

const InterventionDetailsContextComponent = (props) => {
  return (
    <InterventionDetailsContext>
      <InterventionDetails {...props} />
    </InterventionDetailsContext>
  );
};

export default InterventionDetailsContextComponent;
